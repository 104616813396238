import React from 'react';
import { motion } from 'framer-motion';

function Gallery({ theme }) {
  const images = [
    { src: '/placeholder.svg?height=400&width=600', alt: 'Event 1' },
    { src: '/placeholder.svg?height=400&width=600', alt: 'Event 2' },
    { src: '/placeholder.svg?height=400&width=600', alt: 'Event 3' },
    { src: '/placeholder.svg?height=400&width=600', alt: 'Event 4' },
    { src: '/placeholder.svg?height=400&width=600', alt: 'Event 5' },
    { src: '/placeholder.svg?height=400&width=600', alt: 'Event 6' },
  ];

  return (
    <section id="gallery" className={`${theme === 'dark' ? 'bg-zinc-800' : 'bg-zinc-200'} px-4 sm:px-6 lg:px-20 py-24 transition-colors duration-300`}>
      <div className="max-w-7xl mx-auto">
        <span className="text-amber-400 font-medium">Our Work</span>
        <h2 className={`text-4xl sm:text-5xl lg:text-6xl font-black ${theme === 'dark' ? 'text-white' : 'text-zinc-900'} mb-12 tracking-tight leading-none transition-colors duration-300`}>Gallery</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {images.map((image, index) => (
            <motion.div
              key={index}
              className="relative overflow-hidden rounded-xl"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300, damping: 10 }}
            >
              <img
                src={image.src}
                alt={image.alt}
                className="w-full h-64 object-cover"
              />
              <div className={`absolute inset-0 ${theme === 'dark' ? 'bg-zinc-900' : 'bg-zinc-100'} bg-opacity-75 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300`}>
                <p className={`text-lg font-bold ${theme === 'dark' ? 'text-white' : 'text-zinc-900'}`}>{image.alt}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Gallery;

