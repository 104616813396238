import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Sun, Moon } from 'lucide-react';

function Navigation({ theme, toggleTheme, scrollToSection, mounted }) {
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  // Handle navbar hide/show on scroll
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsVisible(lastScrollY > currentScrollY || currentScrollY < 50);
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  const menuItems = [
    { name: 'About', section: 'about' },
    { name: 'Services', section: 'services' },
    { name: 'Gallery', section: 'gallery' },
    { name: 'Contact', section: 'contact' },
  ];

  return (
    <motion.nav 
      initial={{ y: 0 }}
      animate={{ y: isVisible ? 0 : -100 }}
      transition={{ duration: 0.3 }}
      className={`fixed top-0 left-0 right-0 z-50 ${
        theme === 'dark' ? 'bg-zinc-900/95' : 'bg-zinc-100/95'
      } backdrop-blur-md border-b ${
        theme === 'dark' ? 'border-zinc-800' : 'border-zinc-300'
      } transition-colors duration-300`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col sm:flex-row sm:items-center sm:h-16 py-4 sm:py-0">
          <div className="flex justify-between items-center">
            <div className={`text-xl sm:text-3xl font-black ${
              theme === 'dark' ? 'text-white' : 'text-zinc-900'
            } transition-colors duration-300`}>
              <span className="hidden sm:inline">The Tara Thomas Agency</span>
              <span className="sm:hidden">TTA</span>
              <span className="text-amber-400">.</span>
            </div>
            <button 
              onClick={toggleTheme} 
              className="p-2 rounded-full bg-amber-400 text-zinc-900 hover:bg-amber-300 transition-colors duration-200 sm:hidden"
              aria-label={`Switch to ${theme === 'dark' ? 'light' : 'dark'} mode`}
            >
              {mounted && (theme === 'dark' ? <Sun size={20} /> : <Moon size={20} />)}
            </button>
          </div>
          
          <div className="flex flex-row justify-center sm:justify-end items-center mt-4 sm:mt-0 sm:ml-auto space-x-4 overflow-x-auto pb-2 sm:pb-0 scrollbar-hide">
            {menuItems.map((item) => (
              <button
                key={item.section}
                onClick={() => scrollToSection(item.section)}
                className={`${
                  theme === 'dark' ? 'text-zinc-400 hover:text-white' : 'text-zinc-600 hover:text-zinc-900'
                } whitespace-nowrap text-sm sm:text-base transition-colors duration-200 px-2`}
              >
                {item.name}
              </button>
            ))}
            <button 
              onClick={toggleTheme} 
              className="hidden sm:block p-2 rounded-full bg-amber-400 text-zinc-900 hover:bg-amber-300 transition-colors duration-200"
              aria-label={`Switch to ${theme === 'dark' ? 'light' : 'dark'} mode`}
            >
              {mounted && (theme === 'dark' ? <Sun size={20} /> : <Moon size={20} />)}
            </button>
          </div>
        </div>
      </div>
    </motion.nav>
  );
}

export default Navigation;