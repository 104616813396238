import React from 'react';
import { motion } from 'framer-motion';

function About({ theme }) {
  return (
    <section id="about" className={`${theme === 'dark' ? 'bg-zinc-800' : 'bg-zinc-200'} px-4 sm:px-6 lg:px-20 py-24 transition-colors duration-300`}>
      <div className="max-w-7xl mx-auto">
        <span className="text-amber-400 font-medium">About Us</span>
        <h2 className={`text-4xl sm:text-5xl lg:text-6xl font-black ${theme === 'dark' ? 'text-white' : 'text-zinc-900'} mb-12 tracking-tight leading-none transition-colors duration-300`}>Our Story of<br />Success</h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
          <div>
            <p className={`${theme === 'dark' ? 'text-zinc-300' : 'text-zinc-700'} mb-6 text-lg transition-colors duration-300`}>The Tara Thomas Agency has received global recognition for its work across the entertainment industry, featured in notable national publications such as the LA Times, ESPN Press, Vibe Magazine, and Source Magazine.</p>
            <p className={`${theme === 'dark' ? 'text-zinc-300' : 'text-zinc-700'} mb-6 text-lg transition-colors duration-300`}>We've spent years working with and developing the most talented and successful acts in entertainment, leveraging our expertise in management, production, marketing, and networking to grow performers and events across the industry.</p>
            <p className={`${theme === 'dark' ? 'text-zinc-300' : 'text-zinc-700'} text-lg transition-colors duration-300`}>This year, we partnered with After 9 Partners to bring Freaknik, a one-of-a-kind music festival, to Atlanta, Georgia, attracting over 17,000 attendees and featuring renowned artists like Pastor Troy, Uncle Luke, Little Scrappy, Adina Howard, and the East Side Boyz.</p>
          </div>
          <div className="relative">
            <div className="absolute -top-4 -left-4 w-32 h-32 bg-amber-400/20 rounded-full blur-xl"></div>
            <img 
              src="/placeholder.svg?height=600&width=500" 
              alt="Tara Thomas" 
              className="rounded-xl w-full h-full object-cover shadow-2xl shadow-zinc-900/50"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
