import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight } from 'lucide-react';

function Testimonials({ theme }) {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const testimonials = [
    { name: "John Doe", role: "Music Producer", text: "Working with The Tara Thomas Agency has been a game-changer for my career. Their expertise and connections in the industry are unmatched." },
    { name: "Jane Smith", role: "Rising Artist", text: "The team at Tara Thomas Agency helped me navigate the complex world of entertainment. I couldn't have asked for better representation." },
    { name: "Mike Johnson", role: "Event Organizer", text: "Their event production skills are top-notch. They turned our vision into reality and exceeded all expectations." }
  ];

  const nextTestimonial = () => {
    setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentTestimonial((prev) => (prev - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <section className={`${theme === 'dark' ? 'bg-zinc-900' : 'bg-zinc-100'} px-4 sm:px-6 lg:px-20 py-24 transition-colors duration-300`}>
      <div className="max-w-7xl mx-auto">
        <span className="text-amber-400 font-medium">What Our Clients Say</span>
        <h2 className={`text-4xl sm:text-5xl lg:text-6xl font-black ${theme === 'dark' ? 'text-white' : 'text-zinc-900'} mb-12 tracking-tight leading-none transition-colors duration-300`}>Client<br />Testimonials</h2>
        <div className="relative px-8 sm:px-16">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentTestimonial}
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.5 }}
              className="bg-amber-400 p-8 rounded-xl shadow-xl"
            >
              <p className="text-zinc-900 text-lg mb-4">{testimonials[currentTestimonial].text}</p>
              <p className="text-zinc-900 font-bold">{testimonials[currentTestimonial].name}</p>
              <p className="text-zinc-700">{testimonials[currentTestimonial].role}</p>
            </motion.div>
          </AnimatePresence>
          <button
            onClick={prevTestimonial}
            className="absolute top-1/2 left-0 sm:-left-8 transform -translate-y-1/2 bg-amber-400 text-zinc-900 p-2 rounded-full"
            aria-label="Previous testimonial"
          >
            <ChevronLeft size={24} />
          </button>
          <button
            onClick={nextTestimonial}
            className="absolute top-1/2 right-0 sm:-right-8 transform -translate-y-1/2 bg-amber-400 text-zinc-900 p-2 rounded-full"
            aria-label="Next testimonial"
          >
            <ChevronRight size={24} />
          </button>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;

