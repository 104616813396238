import React from 'react';
import { motion } from 'framer-motion';

function Hero({ theme }) {
  return (
    <section className="relative h-screen overflow-hidden">
      <video 
        className="absolute w-full h-full object-cover opacity-50"
        autoPlay 
        muted 
        loop 
        playsInline
      >
        <source src="https://tarathomasagency.com/video/header.mp4" type="video/mp4" />
      </video>
      <div className={`absolute inset-0 ${theme === 'dark' ? 'bg-gradient-to-b from-zinc-900 via-zinc-900/80 to-zinc-900' : 'bg-gradient-to-b from-zinc-100 via-zinc-100/80 to-zinc-100'} transition-colors duration-300`}></div>
      <div className="relative z-10 h-full flex flex-col items-center justify-center text-center px-4 sm:px-6 lg:px-20 py-12">
        <span className="text-amber-400 font-medium">Global Entertainment Industry Leader</span>
        <h1 className={`text-3xl sm:text-5xl lg:text-7xl font-black ${theme === 'dark' ? 'text-white' : 'text-zinc-900'} mb-6 tracking-tight leading-none transition-colors duration-300`}>ELEVATE<br />YOUR PRESENCE</h1>
        <p className={`${theme === 'dark' ? 'text-zinc-400' : 'text-zinc-600'} max-w-xl mb-8 transition-colors duration-300`}>Industry-leading agency working with top celebrities, artists, and multi-platinum producers worldwide.</p>
        <motion.button 
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="bg-amber-400 text-zinc-900 px-8 py-4 rounded-lg text-lg font-bold hover:bg-amber-300 transition-all duration-200 shadow-xl shadow-amber-400/20"
        >
          Work With Us
        </motion.button>
      </div>
      <div className={`absolute bottom-0 left-0 right-0 flex flex-wrap justify-between items-center py-8 px-4 sm:px-6 lg:px-20 ${theme === 'dark' ? 'bg-gradient-to-t from-zinc-900 via-zinc-900/80 to-transparent' : 'bg-gradient-to-t from-zinc-100 via-zinc-100/80 to-transparent'} transition-colors duration-300`}>
        {['Disney', 'LA Times', 'ESPN Press', 'Vibe Magazine', 'Source Magazine', 'After 9 Partners'].map((partner) => (
          <span key={partner} className={`${theme === 'dark' ? 'text-zinc-500 hover:text-white' : 'text-zinc-600 hover:text-zinc-900'} text-sm transition-colors duration-200 cursor-pointer mb-2 sm:mb-0`}>
            {partner}
          </span>
        ))}
      </div>
    </section>
  );
}

export default Hero;
