import React from 'react';
import { motion } from 'framer-motion';
import * as lucideIcons from 'lucide-react';

function Services({ theme }) {
  const services = [
    {
      title: 'Artist Management',
      description: 'Professional guidance and career development for artists',
      icon: 'User'
    },
    {
      title: 'Event Production',
      description: 'Full-scale event planning and execution services',
      icon: 'Calendar'
    },
    {
      title: 'Marketing Strategy',
      description: 'Targeted campaigns to boost your brand presence',
      icon: 'BarChart'
    },
    {
      title: 'Brand Development',
      description: 'Create and evolve your unique brand identity',
      icon: 'Palette'
    },
    {
      title: 'Media Relations',
      description: 'Strategic press and media coverage planning',
      icon: 'Newspaper'
    },
    {
      title: 'Tour Management',
      description: 'Comprehensive tour planning and execution',
      icon: 'Map'
    },
    {
      title: 'Content Production',
      description: 'High-quality content creation for all platforms',
      icon: 'Video'
    },
    {
      title: 'Talent Development',
      description: 'Nurture and grow emerging industry talent',
      icon: 'Star'
    },
    {
      title: 'Industry Networking',
      description: 'Connect with key players in entertainment',
      icon: 'Users'
    }
  ];

  return (
    <section id="services" className={`${theme === 'dark' ? 'bg-zinc-900' : 'bg-zinc-100'} px-4 sm:px-6 lg:px-20 py-16 transition-colors duration-300`}>
      <div className="max-w-7xl mx-auto">
        <span className="text-amber-400 font-medium">Our Services</span>
        <h2 className={`text-4xl sm:text-5xl lg:text-6xl font-black ${theme === 'dark' ? 'text-white' : 'text-zinc-900'} mb-12 tracking-tight leading-none transition-colors duration-300`}>Comprehensive<br />Solutions</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {services.map((service) => {
            const IconComponent = lucideIcons[service.icon];
            return (
              <motion.div
                key={service.title}
                className={`group cursor-pointer rounded-xl p-6 ${theme === 'dark' ? 'bg-zinc-800/50 hover:bg-zinc-800' : 'bg-zinc-200/50 hover:bg-zinc-200'} transition-all duration-300`}
                whileHover={{ scale: 1.02 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
              >
                <div className="relative mb-4">
                  <div className="w-12 h-12 rounded-lg bg-amber-400 flex items-center justify-center">
                    <IconComponent className="w-6 h-6 text-zinc-900" />
                  </div>
                </div>
                <h3 className={`text-xl font-bold ${theme === 'dark' ? 'text-white' : 'text-zinc-900'} mb-2 transition-colors duration-300`}>
                  {service.title}
                </h3>
                <p className={`${theme === 'dark' ? 'text-zinc-400' : 'text-zinc-600'} text-sm transition-colors duration-300`}>
                  {service.description}
                </p>
              </motion.div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Services;

