import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Phone } from 'lucide-react';
import Navigation from './components/Navigation';
import Hero from './components/Hero';
import About from './components/About';
import Services from './components/Services';
import Gallery from './components/Gallery';
import Testimonials from './components/Testimonials';
import Contact from './components/Contact';
import CTA from './components/CTA';

function App() {
  const [theme, setTheme] = useState('dark');
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-zinc-900' : 'bg-zinc-100'} transition-colors duration-300`}>
      <Navigation theme={theme} toggleTheme={toggleTheme} scrollToSection={scrollToSection} mounted={mounted} />
      <Hero theme={theme} />
      <About theme={theme} />
      <Services theme={theme} />
      <Gallery theme={theme} />
      <Testimonials theme={theme} />
      <Contact theme={theme} />
      <CTA theme={theme} />
      <motion.button 
        onClick={() => scrollToSection('contact')}
        className="fixed bottom-8 right-8 bg-amber-400 text-zinc-900 p-4 rounded-full shadow-lg hover:bg-amber-300 transition-all duration-200"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        aria-label="Contact us"
      >
        <Phone size={24} />
      </motion.button>
    </div>
  );
}

export default App;

