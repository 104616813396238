import React, { useState } from 'react';
import { motion } from 'framer-motion';

function Contact({ theme }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log('Form submitted:', formData);
    // Reset form after submission
    setFormData({ name: '', email: '', message: '' });
    alert('Thank you for your message. We will get back to you soon!');
  };

  return (
    <section id="contact" className={`${theme === 'dark' ? 'bg-zinc-800' : 'bg-zinc-200'} px-4 sm:px-6 lg:px-20 py-24 transition-colors duration-300`}>
      <div className="max-w-7xl mx-auto">
        <span className="text-amber-400 font-medium">Connect With Us</span>
        <h2 className={`text-4xl sm:text-5xl lg:text-6xl font-black ${theme === 'dark' ? 'text-white' : 'text-zinc-900'} mb-16 tracking-tight leading-none transition-colors duration-300`}>Let's Create<br />Together</h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16">
          <div>
            <h3 className={`text-2xl font-bold ${theme === 'dark' ? 'text-white' : 'text-zinc-900'} mb-6 transition-colors duration-300`}>Follow Us</h3>
            <div className="space-y-4">
              Email: <a href="#" target="_blank" rel="noopener noreferrer" className={`flex items-center ${theme === 'dark' ? 'text-zinc-400 hover:text-white' : 'text-zinc-600 hover:text-zinc-900'} transition-colors duration-200`}>
                <span className="ml-2">tarathomas@tarathomasagency.com</span>
              </a>
              Phone: <a href="#" target="_blank" rel="noopener noreferrer" className={`flex items-center ${theme === 'dark' ? 'text-zinc-400 hover:text-white' : 'text-zinc-600 hover:text-zinc-900'} transition-colors duration-200`}>
                <span className="ml-2">812-558-8882</span>
              </a>
            </div>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="name" className={`block ${theme === 'dark' ? 'text-zinc-300' : 'text-zinc-700'} font-medium mb-2 transition-colors duration-300`}>Name</label>
              <input
                type="text"
                id="name"
                className={`w-full px-4 py-3 rounded-lg ${theme === 'dark' ? 'bg-zinc-700/50 border-zinc-600 text-white' : 'bg-white border-zinc-300 text-zinc-900'} border focus:border-amber-400 outline-none transition-colors duration-200`}
                value={formData.name}
                onChange={(e) => setFormData({...formData, name: e.target.value})}
                required
              />
            </div>
            <div>
              <label htmlFor="email" className={`block ${theme === 'dark' ? 'text-zinc-300' : 'text-zinc-700'} font-medium mb-2 transition-colors duration-300`}>Email</label>
              <input
                type="email"
                id="email"
                className={`w-full px-4 py-3 rounded-lg ${theme === 'dark' ? 'bg-zinc-700/50 border-zinc-600 text-white' : 'bg-white border-zinc-300 text-zinc-900'} border focus:border-amber-400 outline-none transition-colors duration-200`}
                value={formData.email}
                onChange={(e) => setFormData({...formData, email: e.target.value})}
                required
              />
            </div>
            <div>
              <label htmlFor="message" className={`block ${theme === 'dark' ? 'text-zinc-300' : 'text-zinc-700'} font-medium mb-2 transition-colors duration-300`}>Message</label>
              <textarea
                id="message"
                rows={4}
                className={`w-full px-4 py-3 rounded-lg ${theme === 'dark' ? 'bg-zinc-700/50 border-zinc-600 text-white' : 'bg-white border-zinc-300 text-zinc-900'} border focus:border-amber-400 outline-none resize-none transition-colors duration-200`}
                value={formData.message}
                onChange={(e) => setFormData({...formData, message: e.target.value})}
                required
              ></textarea>
            </div>
            <motion.button 
              type="submit" 
              className="bg-amber-400 text-zinc-900 px-8 py-3 rounded-lg text-lg font-bold hover:bg-amber-300 transition-all duration-200 shadow-lg shadow-amber-400/20"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Send Message
            </motion.button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Contact;

