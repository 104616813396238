import React from 'react';
import { motion } from 'framer-motion';

function CTA({ theme }) {
  return (
    <section className={`${theme === 'dark' ? 'bg-zinc-900' : 'bg-zinc-100'} px-4 sm:px-6 lg:px-20 py-32 relative overflow-hidden transition-colors duration-300`}>
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-r from-amber-400/10 to-amber-400/5"></div>
      </div>
      <div className="max-w-7xl mx-auto relative z-10">
        <div className="text-center max-w-4xl mx-auto">
          <span className="text-amber-400 font-medium">Join Our Success Story</span>
          <h2 className={`text-4xl sm:text-5xl lg:text-7xl font-black ${theme === 'dark' ? 'text-white' : 'text-zinc-900'} my-8 tracking-tight leading-none transition-colors duration-300`}>Transform Your Entertainment Career Today</h2>
          <p className={`${theme === 'dark' ? 'text-zinc-400' : 'text-zinc-600'} mb-12 text-lg transition-colors duration-300`}>Join the ranks of successful artists and brands that have elevated their presence with our expertise.</p>
          <motion.button 
            className="bg-amber-400 text-zinc-900 px-10 py-4 rounded-lg text-lg font-bold hover:bg-amber-300 transition-all duration-200 shadow-xl shadow-amber-400/20"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Get Started
          </motion.button>
        </div>
      </div>
    </section>
  );
}

export default CTA;

